import React, { useState } from "react";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <section className="flex items-center justify-center p-8 my-10">
      <section className="dots-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </section>
    </section>
  ) : (
    <>
      <div className="p-6 sm:mx-auto max-w-3xl bg-white border border-gray-200 rounded-lg shadow my-3">
        <h1 className="text-2xl font-bold text-center">About Us</h1>
      </div>
      <div className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-3">
        <h1 className="my-3">
          Welcome to <b>HTML5Game.Today!</b>
        </h1>

        <p className="my-3">
          At HTML5Game.Today, we are passionate about bringing you the best
          HTML5 games the web has to offer. Our mission is to provide a fun,
          accessible, and engaging gaming experience for players of all ages.
          Whether you're looking for a quick game to pass the time or an
          immersive adventure, you'll find a wide variety of high-quality games
          to suit your tastes.
        </p>

        <h6 className="font-bold my-3">Who We Are</h6>

        <p className="my-3">
          We are a dedicated team of game enthusiasts, developers, and designers
          who believe in the power of HTML5 technology. Our diverse backgrounds
          and skills come together to curate and create a platform where you can
          discover, play, and enjoy games that run seamlessly on any device.
        </p>

        <h6 className="font-bold my-3">Who We Offer</h6>

        <ul className="list-disc mx-3 space-y-1  list-inside">
          <li className="my-2">
            <b>A Wide Selection of Games:</b> From action-packed adventures to
            relaxing puzzles, we have something for everyone.
          </li>
          <li className="my-2">
            <b>Free and Accessible:</b> All our games are free to play, and you
            can access them from any device with a web browser.
          </li>
          <li className="my-2">
            <b>Quality and Safety:</b> We prioritize quality and ensure that all
            our games are safe and enjoyable for all users.
          </li>
          <li className="my-2">
            <b>Community Focused:</b> We value our gaming community and
            encourage feedback to continually improve and expand our offerings.
          </li>
        </ul>

        <h6 className="font-bold my-3">Our Vision</h6>

        <p className="my-3">
          Our vision is to become the go-to destination for HTML5 games online.
          We aim to build a vibrant community of gamers who can share their
          experiences, discover new games, and connect with others who share
          their passion.
        </p>

        <h6 className="font-bold my-3">Get In Touch</h6>

        <p className="my-3">
          We love to hear from our players! If you have any questions,
          suggestions, or just want to say hi, feel free to reach out to us at
          contact@html5game.today.
        </p>
        <p className="my-3">
          Thank you for choosing HTML5Game.Today. Let the games begin!
        </p>
      </div>
    </>
  );
}
