import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const [activeLink, setActiveLink] = useState(
    currentPath !== "" ? currentPath : "home"
  );

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="bg-zinc-600 rounded-lg shadow text-white m-4 container mx-auto">
        <div className="w-full  mx-auto p-4 md:py-8">
          <div className="sm:flex sm:items-center sm:justify-center">
            <ul className="flex flex-wrap flex-col sm:flex-row items-center mb-6 text-sm font-medium text-white sm:mb-0 ">
              <li className="my-2 sm:my-0">
                <Link
                  to="/"
                  className={`hover:underline me-4 md:me-6 ${
                    activeLink === "home"
                      ? "font-bold text-lime-400"
                      : "md:text-white"
                  }`}
                  onClick={() => handleLinkClick("home")}
                >
                  Home
                </Link>
              </li>
              <li className="my-2 sm:my-0">
                <Link
                  to="/about"
                  className={`hover:underline me-4 md:me-6 ${
                    activeLink === "about"
                      ? "font-bold text-lime-400"
                      : "md:text-white"
                  }`}
                  onClick={() => handleLinkClick("about")}
                >
                  About
                </Link>
              </li>
              <li className="my-2 sm:my-0">
                <Link
                  to="/privacy-policy"
                  className={`hover:underline me-4 md:me-6 ${
                    activeLink === "privacy-policy"
                      ? "font-bold text-lime-400"
                      : "md:text-white"
                  }`}
                  onClick={() => handleLinkClick("privacy-policy")}
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="my-2 sm:my-0">
                <Link
                  to="/term-condition"
                  className={`hover:underline me-4 md:me-6 ${
                    activeLink === "term-condition"
                      ? "font-bold text-lime-400"
                      : "md:text-white"
                  }`}
                  onClick={() => handleLinkClick("term-condition")}
                >
                  Term Condition
                </Link>
              </li>
              <li className="my-2 sm:my-0">
                <Link
                  to="/contact-us"
                  className={`hover:underline me-4 md:me-6 ${
                    activeLink === "contact-us"
                      ? "font-bold text-lime-400"
                      : "md:text-white"
                  }`}
                  onClick={() => handleLinkClick("contact-us")}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
          <span className="block text-sm text-white sm:text-center ">
            <span>© {currentYear}</span>
            <Link to="/" className="hover:underline ms-2">
              html5game.today.
            </Link>
            <span className="ms-3">All Rights Reserved.</span>
          </span>
        </div>
      </footer>
    </>
  );
}
