import React, { useState } from "react";

export default function TermCondition() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <section className="flex items-center justify-center p-8 my-10">
      <section className="dots-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </section>
    </section>
  ) : (
    <>
      <div className="p-6 sm:mx-auto max-w-3xl bg-white border border-gray-200 rounded-lg shadow my-3">
        <h1 className="text-2xl font-bold text-center">Term Condition</h1>
      </div>
      <div className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-3">
        <p className="my-3">
          Welcome to HTML5Game.Today! These terms and conditions outline the
          rules and regulations for the use of our website and services. By
          accessing this website, we assume you accept these terms and
          conditions in full. Do not continue to use HTML5Game.Today if you do
          not accept all of the terms and conditions stated on this page.
        </p>

        <h6 className="font-bold mb-3 mt-6">1. Use of the Website</h6>
        <ul className="list-disc mx-3 space-y-1  list-inside">
          <li className="my-2">
            <b>Eligibility:</b> By using our site, you affirm that you are at
            least 13 years old. If you are under 13, you must have parental
            permission to use our services.
          </li>
          <li className="my-2">
            <b>License:</b> Unless otherwise stated, HTML5Game.Today and/or its
            licensors own the intellectual property rights for all material on
            HTML5Game.Today. All intellectual property rights are reserved. You
            may view and/or print pages from HTML5Game.Today for your own
            personal use, subject to restrictions set in these terms and
            conditions.
          </li>
          <li className="my-2">
            <b>Prohibited Uses:</b> You are prohibited from:
            <ul className="list-disc mx-4 space-y-1  list-inside">
              <li className="my-2">
                Republishing material from HTML5Game.Today
              </li>
              <li className="my-2">
                Selling, renting, or sub-licensing material from
                HTML5Game.Today.
              </li>
              <li className="my-2">
                Reproducing, duplicating, or copying material from
                HTML5Game.Today.
              </li>
              <li className="my-2">
                Redistributing content from HTML5Game.Today (unless content is
                specifically made for redistribution).
              </li>
            </ul>
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">2. User Content</h6>
        <ul className="list-disc mx-3 space-y-1  list-inside">
          <li className="my-2">
            <b>Responsibility:</b> You are responsible for any content you
            upload, post, or otherwise make available on HTML5Game.Today.
          </li>
          <li className="my-2">
            <b>Rights:</b> By posting content on HTML5Game.Today, you grant us a
            non-exclusive, worldwide, royalty-free, and sublicensable right to
            use, reproduce, modify, adapt, publish, translate, create derivative
            works from, distribute, and display such content
          </li>
          <li className="my-2">
            <b>Prohibited Content:</b> You agree not to post content that:
            <ul className="list-disc mx-4 space-y-1  list-inside">
              <li className="my-2">
                Is unlawful, harmful, threatening, abusive, harassing,
                defamatory, vulgar, obscene, invasive of another’s privacy, or
                otherwise objectionable.
              </li>
              <li className="my-2">
                Violates any intellectual property or other proprietary rights
                of any party.
              </li>
            </ul>
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">3. Disclaimers</h6>
        <ul className="list-disc mx-3 space-y-1  list-inside">
          <li className="my-2">
            <b>No Warranties:</b> HTML5Game.Today is provided "as is" without
            any representations or warranties, express or implied.
            HTML5Game.Today makes no representations or warranties in relation
            to this website or the information and materials provided on this
            website.
          </li>
          <li className="my-2">
            <b>Limitations of Liability:</b> In no event shall HTML5Game.Today,
            nor any of its officers, directors, and employees, be liable to you
            for anything arising out of or in any way connected with your use of
            this website, whether such liability is under contract, tort, or
            otherwise. HTML5Game.Today, including its officers, directors, and
            employees, shall not be liable for any indirect, consequential, or
            special liability arising out of or in any way related to your use
            of this website.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">4. Indemnification</h6>
        <p className="my-2">
          You hereby indemnify to the fullest extent HTML5Game.Today from and
          against any and all liabilities, costs, demands, causes of action,
          damages, and expenses (including reasonable attorney's fees) arising
          out of or in any way related to your breach of any of the provisions
          of these terms.
        </p>

        <h6 className="font-bold mb-3 mt-6">
          5. Modifications to the Service and Prices
        </h6>
        <ul className="list-disc mx-3 space-y-1  list-inside">
          <li className="my-2">
            <b>Service Changes:</b> We reserve the right to modify or
            discontinue the service (or any part or content thereof) without
            notice at any time.
          </li>
          <li className="my-2">
            <b>Price Changes:</b> Prices for our games and services are subject
            to change without notice.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">6. Governing Law</h6>
        <p className="my-2">
          These terms and conditions are governed by and construed in accordance
          with the laws of india, and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in india for the
          resolution of any disputes.
        </p>

        <h6 className="font-bold mb-3 mt-6">
          7. Changes to These Terms and Conditions
        </h6>
        <p className="my-2">
          We reserve the right to amend these terms and conditions at any time.
          Any changes will be posted on this page with an updated revision date.
          Your continued use of the website following the posting of changes
          constitutes your acceptance of such changes.
        </p>

        <h6 className="font-bold mb-3 mt-6">8. Contact Information</h6>
        <p className="my-2">
          If you have any questions about these Terms and Conditions, please
          contact us at contact@html5game.today.
        </p>
      </div>
    </>
  );
}
