import React, { useState } from "react";

export default function PrivacyPolicy() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <section className="flex items-center justify-center p-8 my-10">
      <section className="dots-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </section>
    </section>
  ) : (
    <>
      <div className="p-6 sm:mx-auto max-w-3xl bg-white border border-gray-200 rounded-lg shadow my-3">
        <h1 className="text-2xl font-bold text-center">Privacy Policy</h1>
      </div>
      <div className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-3">
        <p className="my-3">
          At HTML5Game.Today, we are committed to protecting your privacy. This
          Privacy Policy outlines the types of information we collect, how we
          use it, and the steps we take to ensure it remains secure.
        </p>

        <h6 className="font-bold mb-3 mt-6">1. Information We Collect</h6>

        <ul className="list-disc mx-3 space-y-1  list-inside">
          <li className="my-2">
            <b>Personal Information:</b> When you interact with our site, you
            may voluntarily provide us with personal information such as your
            name and email address. This typically occurs when you contact us or
            subscribe to our newsletter.
          </li>
          <li className="my-2">
            <b>Non-Personal Information:</b> We automatically collect
            non-personal information, such as browser type, device information,
            IP address, and the pages you visit. This helps us understand how
            our site is used and improve your experience.
          </li>
        </ul>

        <h6 className="font-bold  mb-3 mt-6">2. How We Use Your Information</h6>

        <ul className="list-disc mx-3 space-y-1  list-inside">
          <li className="my-2">
            <b>To Provide and Improve Our Services:</b> We use your information
            to operate and enhance our website and the games we offer.
          </li>
          <li className="my-2">
            <b>Communication:</b> If you provide us with your email address, we
            may send you updates, newsletters, and other communications. You can
            opt-out of these communications at any time.
          </li>
          <li className="my-2">
            <b>Analytics:</b> We use non-personal information for analytical
            purposes to understand usage patterns and improve our services.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">
          3. Cookies and Tracking Technologies
        </h6>

        <p className="my-3">
          We use cookies and similar tracking technologies to enhance your
          experience on our website. Cookies are small data files stored on your
          device that help us remember your preferences and provide a
          personalized experience.
        </p>
        <div className="mx-4 ">
          <h6 className="my-3">Types of Cookies We Use</h6>

          <ul className="list-disc mx-3 space-y-1  list-inside">
            <li className="my-2">
              <b>Essential Cookies:</b> These are necessary for the website to
              function properly.
            </li>
            <li className="my-2">
              <b>Performance and Analytics Cookies:</b> These help us understand
              how you use our site and improve its performance. You can opt-out
              of these communications at any time.
            </li>
            <li className="my-2">
              <b>Functional Cookies:</b> These remember your preferences and
              settings.
            </li>
          </ul>
        </div>

        <h6 className="font-bold  mb-3 mt-6">4. Sharing Your Information</h6>
        <p className="my-3">
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties. We may share non-personal information with
          third-party service providers to help us analyze and improve our site.
        </p>

        <h6 className="font-bold  mb-3 mt-6">5. Data Security</h6>
        <p className="my-3">
          We implement a variety of security measures to protect your personal
          information. While we strive to protect your information, we cannot
          guarantee its absolute security.
        </p>

        <h6 className="font-bold  mb-3 mt-6">6. Third-Party Links</h6>
        <p className="my-3">
          Our website may contain links to third-party websites. We are not
          responsible for the privacy practices of these sites, and we encourage
          you to review their privacy policies.
        </p>

        <h6 className="font-bold  mb-3 mt-6">7. Children's Privacy</h6>
        <p className="my-3">
          HTML5Game.Today is not intended for children under the age of 13. We
          do not knowingly collect personal information from children under 13.
          If we become aware that we have inadvertently collected such
          information, we will take steps to delete it.
        </p>

        <h6 className="font-bold  mb-3 mt-6">
          8. Changes to This Privacy Policy
        </h6>
        <p className="my-3">
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with an updated revision date. We encourage you
          to review this Privacy Policy periodically.
        </p>

        <h6 className="font-bold  mb-3 mt-6">9. Contact Us</h6>
        <p className="my-3">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at contact@html5game.today.
        </p>
        <p className="my-3">
          Thank you for visiting HTML5Game.Today. We value your privacy and are
          committed to protecting your personal information.
        </p>
      </div>
    </>
  );
}
