import { Link } from "react-router-dom";
import React from "react";
export default function Navbar() {
  return (
    <>
      <header className="bg-zinc-600 text-white p-4 rounded-lg shadow  container mx-auto mt-3">
        <nav className=" border-gray-200 ">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img
                src="/navbarLogo.png"
                className="h-15"
                alt="Html5game Today"
              />
            </Link>
          </div>
        </nav>
      </header>
    </>
  );
}
