import React, { useEffect, useState } from "react";
import GamesData from "../Games.json";
import { Link, useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";
import Home from "./Home";
import Games from "../Games.json";

const getRandomRecords = (array, num) => {
  const shuffled = [...array].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
};

export default function Single() {
  const [searchParams] = useSearchParams();
  const slug = searchParams.get("slug");
  const game = GamesData.find((game) => game.slug === slug);

  const [leftGameCard, setLeftGameCard] = useState([]);
  const [rightGameCard, setRightGameCard] = useState([]);

  useEffect(() => {
    setLeftGameCard(getRandomRecords(Games, 3));
    setRightGameCard(getRandomRecords(Games, 3));
  }, []);

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <section className="mx-3">
      <div className="p-6 sm:mx-auto max-w-3xl bg-white border border-gray-200 rounded-lg shadow my-3">
        <h1 className="text-2xl font-bold text-center">
          Title : <span className="text-blue-600">{game?.title}</span>
        </h1>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-2 hidden md:block">
          <div className="grid grid-row-3 gap-6 mt-8">
            {leftGameCard?.map((game, index) => (
              <Link
                to={`/single?slug=${game.slug}`}
                key={index}
                className="group relative bg-gray-100 shadow-gray-500 overflow-hidden shadow-lg rounded-[50px]"
              >
                <img
                  className="object-cover transition-opacity duration-300 ease-in-out opacity-100"
                  src={game.logo}
                  alt={game.title}
                />
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 transition-opacity duration-300 ease-in-out"></div>
                <div className="p-4 absolute bottom-0 w-full text-white transform translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  <h5 className="text-sm sm:text-xl font-bold text-center">
                    {game.title}
                  </h5>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="md:col-span-8 col-span-12 w-full rounded-lg h-[650px] block p-1  border border-gray-200 shadow shadow-gray-500 hover:bg-gray-100 overflow-hidden my-5">
          <iframe
            src={game?.gameurl}
            className="w-full h-full overflow-hidden"
            title={game?.title}
          ></iframe>
        </div>
        <div className="md:col-span-2 hidden md:block">
          <div className="grid grid-row-3 gap-6 mt-8">
            {rightGameCard?.map((game, index) => (
              <Link
                to={`/single?slug=${game.slug}`}
                key={index}
                className="group relative bg-gray-100 shadow-gray-500 overflow-hidden shadow-lg rounded-[50px]"
              >
                <img
                  className="object-cover transition-opacity duration-300 ease-in-out opacity-100"
                  src={game.logo}
                  alt={game.title}
                />
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 transition-opacity duration-300 ease-in-out"></div>
                <div className="p-4 absolute bottom-0 w-full text-white transform translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  <h5 className="text-sm sm:text-xl font-bold text-center">
                    {game.title}
                  </h5>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      {game.description && (
        <div
          className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-3"
          dangerouslySetInnerHTML={createMarkup(game.description)}
        ></div>
      )}
      {game.sec_desc && (
        <div
          className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-3"
          dangerouslySetInnerHTML={createMarkup(game.sec_desc)}
        ></div>
      )}
      <Home />
    </section>
  );
}
