import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Games from "../Games.json";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export default function Home() {
  const [isLoad, setIsLoad] = useState(true);
  const [randomGames, setRandomGames] = useState([]);

  useEffect(() => {
    const shuffledGames = shuffleArray([...Games]).slice(0, 20);
    setRandomGames(shuffledGames);
  }, []);

  setTimeout(() => {
    setIsLoad(false);
  }, 1000);

  return isLoad ? (
    <section className="flex items-center justify-center p-8 my-10">
      <section className="dots-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </section>
    </section>
  ) : (
    <section className="flex items-center justify-center p-8">
      <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 w-full">
        {randomGames.map((data, index) => (
          <Link
            to={`/single?slug=${data.slug}`}
            key={index}
            className="group relative bg-gray-100 shadow-gray-500 overflow-hidden shadow-lg rounded-[50px]"
          >
            <img
              className="w-full h-full object-cover transition-opacity duration-300 ease-in-out opacity-100"
              src={data.logo}
              alt={data.title}
            />
            <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 transition-opacity duration-300 ease-in-out"></div>
            <div className="p-4 absolute bottom-0 w-full text-white transform translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
              <h5 className="text-sm sm:text-xl font-bold text-center">
                {data.title}
              </h5>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
}
